const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/7a.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/7b.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/7c.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/8.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/9.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/10.png",
    text: "13",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/11.png",
    text: "14",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/12.png",
    text: "15",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/13.png",
    text: "16",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/14.png",
    text: "17",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/15.png",
    text: "18",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/16.png",
    text: "19",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/17.png",
    text: "20",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/18.png",
    text: "21",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/19.png",
    text: "22",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/20.png",
    text: "23",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/itcrajkot/21.png",
    text: "24",
  },
];

export default data;
